// Подключение свайпера
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, FreeMode } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay, FreeMode]);

const mySwiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    speed: 6500,
    direction: 'horizontal',
    autoplay: { delay: 0 },
    loop: true,
    slidesPerView: 1,
    freeMode: true,
    breakpoints: {
        320: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        640: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 5.5,
            spaceBetween: 30,
        },
    },
});

// const swiper = new Swiper(el, {
//     slidesPerView: 'auto',
// });

const loopSlider = new Swiper('.loop-slider', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 25,
    navigation: {
        nextEl: '.loop-swiper-button-next',
        prevEl: '.loop-swiper-button-prev',
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 2,
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 2,
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 4,
        },
    },
});

const galleryThumbs = new Swiper('.product-slider-thumbs', {
    spaceBetween: 17,
    slidesPerView: 6,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    direction: 'vertical',
    autoHeight: true, //enable auto height
    // freeMode: true,
});
const galleryTop = new Swiper('.product-slider', {
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    preventInteractionOnTransition: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
    thumbs: {
        swiper: galleryThumbs,
    },
});

galleryTop.on('slideChangeTransitionStart', function () {
    // galleryThumbs.slideTo(galleryTop.activeIndex);
});

galleryThumbs.on('transitionStart', function () {
    // galleryTop.slideTo(galleryThumbs.activeIndex);
});

const postSwiper = new Swiper('.vk-posts', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
        el: '.swiper-pagination',
    },
    navigation: {
        nextEl: '.loop-swiper-button-next',
        prevEl: '.loop-swiper-button-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 4,
        },
    },
});
