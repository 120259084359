document.addEventListener('DOMContentLoaded', () => {
    function mobNavClass() {
        document.querySelector('.hamburger').classList.toggle('animate');
        document.querySelector('.header').classList.toggle('show');
        document.querySelector('.header__mob-wrap').classList.toggle('on');
        document.querySelector('.header-nav').classList.toggle('on');
        document.querySelector('.header__wrap').classList.toggle('on');
        document.querySelector('body').classList.toggle('is-fixed');
    }

    function mobNavClick() {
        document
            .getElementById('hamburger-btn')
            .addEventListener('click', (event) => {
                mobNavClass();
            });
    }

    if (window.innerWidth < 480) {
        mobNavClick();
    }

    window.addEventListener('resize', function (event) {
        if (window.innerWidth < 480) {
            mobNavClick();
        }
    });
});
