document.addEventListener('DOMContentLoaded', function () {
    function updateCart() {
        let updateButton = document.querySelector('button[name="update_cart"]');
        if (updateButton) {
            updateButton.removeAttribute('disabled');
            updateButton.click();
        }
    }

    function updateAddToCartButton(input) {
        let productForm = input.closest('.product-form');
        if (productForm) {
            let addToCartButton = productForm.querySelector(
                '.add_to_cart_button'
            );
            if (addToCartButton) {
                addToCartButton.setAttribute('data-quantity', input.value);
            }
        }
    }

    document.addEventListener('click', function (event) {
        let target = event.target;
        let input;

        // Для страницы товара
        if (document.body.classList.contains('single-product')) {
            if (
                target.classList.contains('inc') ||
                target.classList.contains('dec')
            ) {
                input = target.closest('.pro-qty').querySelector('input.qty');
                if (input) {
                    let newValue =
                        parseInt(input.value) +
                        (target.classList.contains('inc') ? 1 : -1);
                    if (newValue > 0) {
                        input.value = newValue;
                        input.dispatchEvent(
                            new Event('input', { bubbles: true })
                        ); // Изменено с "change" на "input"
                    }
                }
            }
        }

        // Для страницы корзины
        if (document.body.classList.contains('woocommerce-cart')) {
            if (
                target.classList.contains('inc') ||
                target.classList.contains('dec')
            ) {
                input = target.closest('.pro-qty').querySelector('input.qty');
                if (input) {
                    let newValue =
                        parseInt(input.value) +
                        (target.classList.contains('inc') ? 1 : -1);
                    if (newValue > 0) {
                        input.value = newValue;
                        input.dispatchEvent(
                            new Event('change', { bubbles: true })
                        );
                    }
                }
            }
        }
    });

    // Изменено: теперь следим за "input" вместо "change"
    document.addEventListener('input', function (event) {
        if (
            event.target.classList.contains('qty') &&
            document.body.classList.contains('single-product')
        ) {
            updateAddToCartButton(event.target);
        }
    });

    document.addEventListener('change', function (event) {
        if (
            event.target.classList.contains('qty') &&
            document.body.classList.contains('woocommerce-cart')
        ) {
            updateCart();
        }
    });

    document.body.addEventListener('updated_cart_totals', function () {
        console.log('Корзина обновлена!');
    });
});

import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

document.addEventListener('DOMContentLoaded', function () {
    function initFlatpickr() {
        let input = document.querySelector('#shipping_datatime');
        if (!input) {
            return;
        }

        let now = new Date();
        let minDate = new Date();
        let minTime = '07:30';
        let maxTime = '20:00';
        let defaultDate;

        // Если текущее время >= 19:30, переносим выбор на следующий день с 07:30
        if (
            now.getHours() > 19 ||
            (now.getHours() === 19 && now.getMinutes() > 30)
        ) {
            minDate.setDate(minDate.getDate() + 1);
            minDate.setHours(7, 30, 0, 0);
            defaultDate = new Date(minDate); // Завтра 07:30
        }
        // Если текущее время < 07:30, оставляем 07:30
        else if (
            now.getHours() < 7 ||
            (now.getHours() === 7 && now.getMinutes() < 30)
        ) {
            minDate.setHours(7, 30, 0, 0);
            defaultDate = new Date(minDate); // Сегодня 07:30
        }
        // Если текущее время между 07:30 и 19:30, ставим минимум через 1 час
        else {
            let nextHour = now.getHours() + 1;
            let nextMinutes = now.getMinutes();
            let newMinTime =
                nextMinutes > 30 ? `${nextHour}:00` : `${now.getHours()}:30`;

            minDate.setHours(nextHour, nextMinutes > 30 ? 0 : 30, 0, 0);
            minTime = newMinTime;
            defaultDate = new Date(minDate); // Ближайшее доступное время
        }

        let fp = flatpickr(input, {
            enableTime: true,
            dateFormat: 'd.m.Y H:i',
            time_24hr: true,
            minDate: minDate,
            minuteIncrement: 30,
            minTime: minTime,
            maxTime: maxTime,
            defaultDate: defaultDate, // Устанавливаем правильное время
            locale: Russian,

            // Проверка при изменении даты/времени
            onChange: function (selectedDates, dateStr, instance) {
                checkSelectedDate(selectedDates, instance);
            },

            // Проверка при закрытии календаря
            onClose: function (selectedDates, dateStr, instance) {
                checkSelectedDate(selectedDates, instance);
            },
        });

        function checkSelectedDate(selectedDates, instance) {
            if (selectedDates.length > 0) {
                let selectedDate = selectedDates[0];
                let now = new Date();

                // Если выбрана сегодняшняя дата
                if (selectedDate.toDateString() === now.toDateString()) {
                    let nowHours = now.getHours();
                    let nowMinutes = now.getMinutes();

                    // Динамически обновляем `minTime` для сегодняшнего дня
                    let newMinTime =
                        nowMinutes > 30
                            ? `${nowHours + 1}:00`
                            : `${nowHours}:30`;
                    instance.set('minTime', newMinTime);

                    // Если время выбрано позднее 19:30
                    if (
                        selectedDate.getHours() > 19 ||
                        (selectedDate.getHours() === 19 &&
                            selectedDate.getMinutes() > 30)
                    ) {
                        setTimeout(() => {
                            alert(
                                'Выбранное время недоступно!\n' +
                                    'Доставка возможна только до 19:30.\n' +
                                    'Свяжитесь с менеджером или выберите другое время.'
                            );
                        }, 100);

                        // Автоматически устанавливаем дату на завтра 07:30
                        let tomorrow = new Date();
                        tomorrow.setDate(now.getDate() + 1);
                        tomorrow.setHours(7, 30, 0, 0);
                        instance.setDate(tomorrow);
                        instance.set('minTime', '07:30');
                    }
                } else {
                    instance.set('minTime', '07:30');
                }
            }
        }

        console.log('Flatpickr инициализирован!');
    }

    initFlatpickr();

    jQuery(document.body).on('updated_checkout', function () {
        console.log('Форма обновилась, перезапускаем Flatpickr...');
        initFlatpickr();
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll('.product-block__item');

    // Функция для открытия/закрытия элемента
    function toggleItem(item) {
        const text = item.querySelector('.product-block__content');

        if (item.classList.contains('active')) {
            // Если элемент уже открыт, закрываем его
            text.style.maxHeight = '0px'; // Устанавливаем высоту в 0
        } else {
            // Рассчитываем высоту содержимого
            text.style.maxHeight = `${text.scrollHeight}px`; // Устанавливаем высоту равной высоте содержимого
        }

        // Переключаем класс active
        item.classList.toggle('active');
    }

    // Установка начальных условий (какие элементы открыты изначально)
    function setInitialStates() {
        // Например, открываем только первый и третий элементы
        // items[0].classList.add('active'); // Первый элемент
        // items[2].classList.add('active'); // Третий элемент

        // Устанавливаем начальную высоту для открытых элементов
        items.forEach((item) => {
            if (item.classList.contains('active')) {
                const text = item.querySelector('.product-block__content');
                text.style.maxHeight = `${text.scrollHeight}px`;
            }
        });
    }

    // Обработчик кликов
    items.forEach((item) => {
        const title = item.querySelector('.product-block__title');
        title.addEventListener('click', () => {
            // Закрываем все остальные элементы, если нужно поведение "один открытый"
            // items.forEach((otherItem) => otherItem !== item && otherItem.classList.remove('active'));

            // Переключаем текущий элемент
            toggleItem(item);
        });
    });

    // Инициализация начальных состояний
    setInitialStates();
});

document.addEventListener('DOMContentLoaded', () => {
    const oneClickBtn = document.getElementById('buy-one-click');

    if (oneClickBtn) {
        oneClickBtn.addEventListener('click', function () {
            // Проверка на наличие класса `adding`
            if (oneClickBtn.classList.contains('adding')) {
                return; // Выходим из функции, если запрос уже выполняется
            }

            const productId = oneClickBtn.getAttribute('data-product-id');

            // Блокируем кнопку и добавляем класс `adding`
            oneClickBtn.classList.add('adding');

            // Создаём запрос для добавления товара в корзину
            fetch(
                `/wp-admin/admin-ajax.php?action=add_to_cart&product_id=${productId}&quantity=1`,
                {
                    method: 'GET',
                    credentials: 'same-origin',
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        // Переход на страницу чекаута после успешного добавления
                        window.location.href = '/checkout/';
                    } else {
                        alert(
                            'Не удалось добавить товар в корзину. Пожалуйста, попробуйте ещё раз.'
                        );
                    }
                })
                .catch((error) => {
                    console.error('Ошибка добавления товара:', error);
                    alert('Произошла ошибка. Пожалуйста, попробуйте позже.');
                })
                .finally(() => {
                    // Разблокируем кнопку и удаляем класс `adding`
                    oneClickBtn.classList.remove('adding');
                });
        });
    }
});

document.addEventListener('DOMContentLoaded', () => {
    // Получаем нужные элементы
    const gotoSocialBtn = document.querySelector('.gotosocial__btn');
    const gotoSocial = document.getElementById('gotosocial');
    const gotoSocialWrap = document.querySelector('.gotosocial__wrap');
    const jsOpenChats = document.querySelector('.jsopenchats');

    if (gotoSocialBtn) {
        // Добавляем обработчик клика для кнопки .gotosocial__btn
        gotoSocialBtn.addEventListener('click', function () {
            this.classList.toggle('on'); // Переключаем класс 'on' для кнопки
            gotoSocial.classList.toggle('on'); // Переключаем класс 'on' для блока #gotosocial
            toggleWrap(); // Вызываем функцию для переключения видимости .gotosocial__wrap
        });
    }

    // Добавляем обработчик клика для элемента .jsopenchats
    if (jsOpenChats) {
        jsOpenChats.addEventListener('click', function () {
            gotoSocial.classList.add('on'); // Добавляем класс 'on' для блока #gotosocial
            gotoSocialBtn.classList.add('on'); // Добавляем класс 'on' для кнопки .gotosocial__btn
            showWrap(); // Вызываем функцию для показа .gotosocial__wrap
        });
    }

    // Функция для переключения видимости и класса 'on' у .gotosocial__wrap
    function toggleWrap() {
        if (gotoSocialWrap.style.display === 'none') {
            gotoSocialWrap.style.display = 'flex';
            gotoSocialWrap.classList.add('on');
        } else {
            gotoSocialWrap.style.display = 'none';
            gotoSocialWrap.classList.remove('on');
        }
    }

    // Функция для показа .gotosocial__wrap и добавления класса 'on'
    function showWrap() {
        gotoSocialWrap.style.display = 'flex';
        gotoSocialWrap.classList.add('on');
    }
});
