document.addEventListener('DOMContentLoaded', function () {
    const categoryElements = document.querySelectorAll('.terms__wrap span');
    const productsContainer = document.querySelector('.products');
    const categoryButton = document.querySelector('.section-block-hits__btn a'); // Кнопка для обновления

    categoryElements.forEach(function (element) {
        element.addEventListener('click', function () {
            const category = this.getAttribute('data-badge');

            // Убираем класс 'active' у всех категорий
            categoryElements.forEach((el) => el.classList.remove('active'));
            // Добавляем класс 'active' к выбранной категории
            this.classList.add('active');

            const formData = new FormData();
            formData.append('action', 'load_products');
            formData.append('category', category);

            // Добавим эффект загрузки
            productsContainer.classList.add('loading');

            fetch(ajax_params.ajax_url, {
                method: 'POST',
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Ошибка сети: ' + response.status);
                    }
                    return response.json(); // Ожидаем JSON от сервера
                })
                .then((data) => {
                    if (data.success) {
                        // Обновляем товары
                        productsContainer.innerHTML = data.data.products_html;

                        // Обновляем текст и ссылку кнопки
                        categoryButton.querySelector('ins').textContent =
                            data.data.category_name;
                        categoryButton.setAttribute(
                            'href',
                            data.data.category_link
                        );
                    } else {
                        productsContainer.innerHTML =
                            '<p>' + data.data + '</p>';
                    }
                })
                .catch((error) => {
                    productsContainer.innerHTML =
                        '<p>Произошла ошибка. Попробуйте позже.</p>';
                    console.error('Ошибка:', error);
                })
                .finally(() => {
                    // Убираем эффект загрузки после завершения запроса
                    productsContainer.classList.remove('loading');
                });
        });
    });
});
