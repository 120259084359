document.addEventListener('DOMContentLoaded', () => {
    const spoilerText = document.querySelector('.block__spoiler--text');
    const showButton = document.querySelector('.block__spoiler--show');

    // Проверяем, существуют ли необходимые элементы
    if (!spoilerText || !showButton) {
        // console.log(
        //     'Элементы .block__spoiler--text или .block__spoiler--show не найдены.'
        // );
        return; // Прерываем выполнение кода, если элементы отсутствуют
    }

    let isExpanded = false;

    // Изначально текст обрезан
    spoilerText.style.maxHeight = '200px'; // Устанавливаем начальную высоту
    showButton.textContent = 'Показать весь текст';

    showButton.addEventListener('click', () => {
        if (isExpanded) {
            // Свернуть текст
            spoilerText.style.maxHeight = '200px';
            spoilerText.classList.add('collapsed');
            showButton.textContent = 'Показать весь текст';
        } else {
            // Развернуть текст
            spoilerText.style.maxHeight = `${spoilerText.scrollHeight}px`; // Вычисляем полную высоту текста
            spoilerText.classList.remove('collapsed');
            showButton.textContent = 'Скрыть текст';
        }
        isExpanded = !isExpanded;
    });
});
